import React from 'react'

const Error = () => {
  return (
    <div className='errorPage'>
      <h1>
      Page Not Found.
      </h1>
    </div>
  )
}

export default Error
