import React from 'react'

const Message = () => {
  return (
    <div className='lochsa'>
      <div>
        <h2>Custom made for the surfer and the wave.</h2>
      </div>
    </div>
  )
}

export default Message
